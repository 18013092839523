@use "sass:map";
@use "@angular/material" as mat;

@mixin theme($theme) {
  .mat-drawer-container {
    background-color: map.get(map.get($theme, background), background);
  }

  .sidenav {
    &.mat-drawer-side {
      background-color: map.get(map.get($theme, background), base);
      border-radius: 0px;
    }
  }
  .mat-sidenav.customizerSidenav {
    width: 330px;
    background-color: map.get(map.get($theme, background), base);
    border-radius: 0px;
  }
}
