//apexcharts

.dark {
    .apexcharts-text {
        color: #c9c9c9;
        fill: #c9c9c9;
    }
    .apexcharts-legend-text {
        color: #c9c9c9 !important;
        fill: #c9c9c9;
    }
}
