.mat-mdc-table {
  box-shadow: none !important;
  .favourite-star {
    height: 20px;
    width: 20px;
    margin: 0px 5px;
  }
  .action-btn-img {
    height: 18px;
    width: 18px;
    margin: 10px;
  }

  .mat-mdc-row:hover {
    background-color: rgba(18, 25, 41, 0.04);
    cursor: pointer;
  }
  &.customHeader {
    .mat-mdc-header-row {
      padding: 0 10px;
      height: 50px;
      vertical-align: middle;
      background-color: #f5f5f5;
      color: #666;
      font-weight: 500;
      border: none;
    }
  }
}
mtx-grid.mtx-grid {
  .mat-header-cell {
    white-space: nowrap;
  }
}
.table-header-toolbar {
  width: 100%;
}
.table-header {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #5b626b;
}

.right-side-btn {
  float: right;
  padding: 0px 10px;
  [dir="rtl"] & {
    float: left;
  }
}

.search-box {
  height: 40px;
  margin: 0px 10px;
}
.table-img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: #fff;
  position: inherit;
  margin-right: 10px;
  [dir="rtl"] & {
    margin-left: 10px;
  }
}
.table-spacer {
  flex: 1 1 auto;
}

.mobile-label {
  display: none;
}
.export-button {
  height: 32px;
  width: 32px;
  vertical-align: text-bottom;
  cursor: pointer;
  margin: 0px 5px;
}
.column-menu {
  padding: 10px;
}
.table-column-label {
  font-size: 14px;
  font-weight: 600;
  padding: 25px;
  line-height: 40px;
}
.text-nowrap {
  white-space: nowrap;
}
.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tbl-icon {
  min-width: 24px;
  &.col-green {
    color: transparent;
    background: #45b449;
    background-clip: text;
  }
  &.col-red {
    color: transparent;
    background: #ff4d4d;
    background-clip: text;
  }
  &.col-blue {
    color: transparent;
    background: #3b82f6;
    background-clip: text;
  }
  &.col-grey {
    color: transparent;
    background: gray;
    background-clip: text;
  }
  &.mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
    line-height: 20px;
  }
}
.tbl-action-btn {
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
}
.tbl-fav-edit {
  color: #6777ef;
  display: inline !important;
  .feather {
    height: 18px !important;
    width: 18px !important;
  }
}
.tbl-fav-delete {
  color: #ff5200;
  display: inline !important;
  .feather {
    height: 20px !important;
    width: 20px !important;
  }
}
@media (max-width: 600px) {
  .mobile-label {
    width: 100px;
    display: inline-block;
    font-weight: bold;
  }

  .advance-table .mdc-data-table__header-row {
    display: none;
  }

  .advance-table .mdc-data-table__row {
    flex-direction: column;
    align-items: start;
    padding: 8px 24px;
    display: grid;
  }
  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 10px;
  }
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
  .tbl-col-width-per-6 {
    max-width: 100%;
  }
  .tbl-col-width-per-7 {
    max-width: 100%;
  }
}
.column-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-img img {
  border-radius: 5px;
  height: 33px;
  width: 33px;
  background: #fff;
  position: inherit;
}
.materialTableHeader {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding-left: 0px;

  .left {
    flex: 35%;
    height: 60px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .header-buttons-left {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 0px;

    .tbl-search-box {
      position: relative;
      margin-left: 10px;
    }

    .searchbox {
      position: relative;
    }

    .search-icon {
      position: absolute;
      top: 10px;
      padding-left: 10px;
      color: rgba(0, 0, 0, 0.54);
    }

    input.search-field {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      border-radius: 5px;
      border: 0;
      height: 45px;
      padding: 8px 8px 8px 50px;
      width: 250px;
      background-color: #eceef4;
      outline: none;

      &:hover {
        background: #eceef4;
      }
    }
  }
}
.simple-table {
  border-collapse: collapse;
  width: 100%;
  thead tr th {
    padding: 0 10px;
    height: 50px;
    vertical-align: middle;
    background-color: #f5f5f5;
    color: #666;
    font-weight: 500;
    border: none;
    text-align: left;
    [dir="rtl"] & {
      text-align: right;
      text-align: unset;
    }
  }
  tbody tr {
    th,
    td {
      padding: 12px;
      border-bottom: 1px solid #eee;
      vertical-align: middle;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
      cursor: pointer;
    }
  }
}
@media (max-width: 380px) {
  .materialTableHeader .header-buttons-left input.search-field {
    width: 140px;
  }
}
@media (max-width: 300px) {
  .materialTableHeader .header-buttons-left input.search-field {
    width: 100px;
  }
}
.dark {
  .table-header {
    color: #ffffff;
  }
  .mat-mdc-table {
    .mat-mdc-row:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
    &.customHeader {
      .mat-mdc-header-row {
        background-color: #141823;
        color: #bfcde3;
      }
    }
  }

  .simple-table {
    thead tr th {
      background-color: rgba(0, 0, 0, 0.1);
      color: #bfcde3;
    }
    tbody tr td {
      border-bottom: 1px solid #3c4758;
    }
  }
}
