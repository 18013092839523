// .mat-drawer-container {
//   height: 100vh;
// }
.display-none {
  display: none;
}
.text-muted {
  color: #96a2b4;
}
.float-start {
  float: left;
  [dir="rtl"] & {
    float: right;
  }
}
.float-end {
  float: right;
  [dir="rtl"] & {
    float: left;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.btn-space {
  margin: 0px 10px;
}
.ng-scroll-content {
  padding: 0px !important;
}
