.info-box1 {
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin: 8px 0px 25px 0px;
  transition: all 0.3s ease-in-out;
  .info-box1-block {
    padding: 25px;
  }
}
.order-info-box1 i {
  font-size: 30px;
}
.info-card {
  margin-top: 20px;
  text-align: right;
  background: #fff;
  min-height: 50px;
  position: relative;
  margin-bottom: 24px;
  border: 1px solid #f2f4f9;
  border-radius: 10px;
  box-shadow: 0 0 10px #b7c0ce33;
  height: 120px;

  .info-box8 {
    padding: 15px 20px;
    .icon {
      float: left;
      margin: 0px 5px;
      font-size: 20px;
      [dir="rtl"] & {
        float: right;
      }
    }
    .labelText {
      [dir="rtl"] & {
        text-align: left;
      }
    }
  }
  .card1-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -15px;
    font-size: 35px;
    border-radius: 8px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }
  .card-block > span {
    color: #919aa3;
  }
  :hover .card1-icon {
    top: -25px;
  }
}
.info-box2 {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #b7c0ce33;
  border: 1px solid #f2f4f9;
}
.appointment-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
  background: #f6f4ff;
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 10px;
    [dir="rtl"] & {
      margin-right: unset;
      margin-left: 10px;
    }
  }
  .dateTime {
    margin-left: auto;
    [dir="rtl"] & {
      margin-right: auto;
      margin-left: unset;
    }
  }
}
.testimonialCard {
  .testimonialBody {
    .material-icons {
      font-size: 50px;
    }
    .testimonialDetails {
      line-height: 1.625;
    }
  }
  .testimonialFooter {
    .userImg {
      width: 2.5rem;
      height: 2.5rem;
      border: 2px solid #b8b8b8;
      border-radius: 50%;
    }
    .userDetails {
      margin: 0px 10px;
      .userName {
        font-size: 0.775rem;
        line-height: 1.25rem;
        margin: 0px;
        color: #8a8a8a;
      }
      .userDesig {
        color: #484848;
        font-weight: 600;
      }
    }
  }
}
.postCard {
  padding: 10px;
  margin-bottom: 20px;

  .profile-pic {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #b8b8b8;
    border-radius: 50%;
  }
  .userDetails {
    margin: 0px 0px 20px 10px;
    [dir="rtl"] & {
      margin: 0px 10px 20px 0px;
    }
    .userName {
      font-size: 0.975rem;
      line-height: 1.25rem;
      margin: 0px;
      font-weight: 600;
    }
    .postType {
      color: #767676;
      font-size: 12px;
      border: none;
      margin-top: 7px;
    }
  }

  textarea {
    padding: 15px 20px;
    border-radius: 10px;
    box-sizing: border-box;
    color: #616161;
    border: 1px solid #e1e1e1;
    font-size: 16px;
    letter-spacing: 1px;
    height: 120px !important;
    width: 100%;
  }

  textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #00c853 !important;
    outline-width: 0 !important;
  }
  .postBtn {
    margin-left: auto;
    [dir="rtl"] & {
      margin-right: auto;
      margin-left: unset;
    }
  }
}

.expenseCard {
  .currencyIcon {
    padding: 15px;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    font-size: 30px;
    color: #6366f1;
  }
  .icon {
    margin-left: auto;
    [dir="rtl"] & {
      margin-right: auto;
      margin-left: unset;
    }
  }
}
.profileCard {
  .socialList {
    padding: 0px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-top: 1px solid #eee;
      cursor: pointer;
    }
  }

  .socialList li:hover {
    background-color: #edf3f9;
    color: #000;
  }
  .profileCardImage {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    margin-bottom: 10px;
  }
}

.review-img {
  padding-left: 20px;
  padding-top: 5px;
  width: 70px;

  img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
  }
}
.order-list {
  padding: 0px;
  margin: 0px;
  .avatar {
    position: relative;
    width: 30px;
    height: 25px;
    white-space: nowrap;
    border-radius: 1000px;
    vertical-align: bottom;
    display: inline-block;
  }
  img {
    border: 2px solid #ffffff;
    box-shadow: 0 2px 10px 0 rgba(107, 111, 130, 0.3);
    height: 28px;
    width: 28px;
    border-radius: 50%;
  }

  li + li {
    margin-left: -14px;
  }

  .badge {
    background: rgb(214 214 214 / 80%);
    color: #6b6f82;
    margin-bottom: 10px;
    padding: 0px 6px;
  }
}
.info-box3 {
  .userImg {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
  .userName {
    margin: 0px;
  }
  .userDetails {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    margin: 10px 0px;
  }
}
.taskBanner {
  .userImg {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
  }
  .img1 {
    position: relative;
    left: 20px;
    z-index: 28;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
    [dir="rtl"] & {
      left: unset;
      right: 20px;
    }
  }
  .img2 {
    position: relative;
    left: 10px;
    z-index: 29;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
    [dir="rtl"] & {
      left: unset;
      right: 10px;
    }
  }
  .img3 {
    z-index: 30;
    border: 0.5px solid #6ebde4;
    border-radius: 50%;
    background-color: #bcd8e6;
  }
}
.followCard {
  img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 40px;
  }
}
.chartData {
  .chartFooter {
    .d1 {
      height: 40px;
    }
  }
}
.info-card2 {
  margin-top: 20px;
  background: #fff;
  min-height: 50px;
  position: relative;
  margin-bottom: 24px;
  border: 1px solid #f2f4f9;
  border-radius: 10px;
  box-shadow: 0 0 10px #b7c0ce33;
  height: 140px;
  .card-body {
    padding: 15px;
  }
  .orange-progress .mat-progress-bar-fill::after {
    background-color: green !important;
  }
}
.progressCard {
  margin: 0px 20px;
  ul {
    list-style-type: none;
  }
  .userImgGroup {
    text-align: right;
    .userImg {
      margin-left: -15px;
      position: relative;
      z-index: 0;
      transition: all 0.3s ease-in-out;
      &:hover {
        z-index: 9;
      }
      img {
        border-radius: 50%;
        border: 2px solid #ffffff;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
        height: 30px;
        width: 30px;
      }
    }
  }
}
.leave-card {
  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: 25px;
    justify-content: flex-start;
    .card-header-inner {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .card-body {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .my-storage {
      font-size: 25px;
    }
  }
  .card-footer {
    align-self: stretch;
    height: 119px;
    flex-shrink: 0;
    flex-direction: row;
    padding: 25px;
    box-sizing: border-box;
    font-size: 14px;
    .bulletsGroup {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .bullets1 {
        position: relative;
        border-radius: 4px;
        background-color: #f67b0e;
        width: 20px;
        height: 20px;
        overflow: hidden;
        flex-shrink: 0;
        margin: 10px;
      }
      .bullets2 {
        position: relative;
        border-radius: 4px;
        background-color: #d6d6d6;
        width: 20px;
        height: 20px;
        overflow: hidden;
        flex-shrink: 0;
        margin: 10px;
      }
      .bulletText {
        position: relative;
        font-weight: 500;
      }
    }
  }
}

.profilePostHeader {
  .profile-pic {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #b8b8b8;
    border-radius: 50%;
  }
  .userDetails {
    margin: 0px 10px;
    .userName {
      font-size: 0.975rem;
      line-height: 1.25rem;
      margin: 0px;
      color: #3c3c3c;
      font-weight: 600;
    }
    .postType {
      color: #767676;
      font-size: 12px;
      border: none;
      margin-top: 7px;
    }
    .postTime {
      line-height: 1;
      color: #767676;
      font-size: 12px;
      margin-top: 2px;
    }
  }
}
.cpCard {
  border-bottom: 1px solid #eee;
  padding-bottom: 7px;
}
.dark {
  .appointment-list {
    background: #161a25;
  }
  .info-card {
    background: #1a202e;
    border: 1px solid #1a202e;
  }
  .info-card2 {
    background: #1a202e;
    border: 1px solid #1a202e;
  }
  .info-box2 {
    background: #1a202e;
    border: 1px solid #1a202e;
  }
  .testimonialCard .testimonialFooter .userDetails .userDesig {
    color: #bfcde3;
  }
  .postCard {
    .userDetails .postType {
      background: #1a202e;
    }
    textarea {
      background: #1a202e;
    }
  }
  .cpCard {
    border-bottom: 1px solid #3c4758;
  }
  .profileCard .socialList li {
    border-top: 1px solid #3c4758;
    &:hover {
      background-color: #181c26;
      color: #bfcde3;
    }
  }
}
