$custom-themes: (
  default: (
    selector: ".theme-default",
    primary: (
      0: #0f1825, // Black for contrast
      10: #172437, // Darker shade of the primary color
      20: #1e3049, // Deeper blue
      25: #263c5c, // Middle blue
      30: #2d486e, // Darker blue
      35: #3c6092, // Primary color
      40: #4d78af, // Light blue
      50: #5e90ea, // Lighter blue
      60: #6fa2e5, // Lightest blue
      70: #7cb4e0, // Very light blue
      80: #8083ff, // Lightest blue
      90: #c4cfde, // Light gray
      95: #d8dfe9, // Lighter gray
      98: #ebeff4, // Almost white
      99: #ffffff, // Off-white
      100: #ffffff, // White
    ),
    secondary: (
      0: #000000,
      10: #191a2c,
      20: #2e2f42,
      25: #393a4d,
      30: #454559,
      35: #515165,
      40: #5d5c72,
      50: #76758b,
      60: #8f8fa5,
      70: #aaa9c1,
      80: #c6c4dd,
      90: #e2e0f9,
      95: #f2efff,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #2e1125,
      20: #46263a,
      25: #523146,
      30: #5f3c51,
      35: #6c475d,
      40: #795369,
      50: #946b83,
      60: #af849d,
      70: #cc9eb8,
      80: #e9b9d3,
      90: #ffd8ec,
      95: #ffecf4,
      98: #fff8f9,
      99: #fffbff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
  teal: (
    selector: ".theme-teal",
    primary: (
      0: #000000,
      10: #00201c,
      20: #003731,
      25: #00443d,
      30: #005048,
      35: #005d54,
      40: #006a60,
      50: #008679,
      60: #00a293,
      70: #2cbfae,
      80: #53dbc9,
      90: #74f8e5,
      95: #b4fff2,
      98: #e4fff9,
      99: #f2fffb,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #05201c,
      20: #1c3531,
      25: #27403c,
      30: #334b47,
      35: #3e5753,
      40: #4a635f,
      50: #627c77,
      60: #7c9691,
      70: #96b1ab,
      80: #b1ccc6,
      90: #cce8e2,
      95: #daf7f0,
      98: #e4fff9,
      99: #f2fffb,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #001e31,
      20: #153349,
      25: #213e55,
      30: #2d4961,
      35: #39556d,
      40: #456179,
      50: #5e7a93,
      60: #7894ae,
      70: #92aec9,
      80: #adcae6,
      90: #cce5ff,
      95: #e7f2ff,
      98: #f7f9ff,
      99: #fcfcff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
  red: (
    selector: ".theme-red",
    primary: (
      0: #000000,
      10: #410001,
      20: #690002,
      25: #7e0004,
      30: #930005,
      35: #a90107,
      40: #bb1614,
      50: #e0342a,
      60: #ff5545,
      70: #ff8a7b,
      80: #ffb4a9,
      90: #ffdad5,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #2c1512,
      20: #442926,
      25: #513430,
      30: #5d3f3b,
      35: #6a4b46,
      40: #775652,
      50: #926f6a,
      60: #ae8883,
      70: #caa29c,
      80: #e7bdb7,
      90: #ffdad5,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #261a00,
      20: #3e2e04,
      25: #4b390e,
      30: #574419,
      35: #645023,
      40: #705c2e,
      50: #8b7444,
      60: #a68e5b,
      70: #c2a873,
      80: #dfc38c,
      90: #fcdfa6,
      95: #ffefd4,
      98: #fff8f2,
      99: #fffbff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
  orange: (
    selector: ".theme-orange",
    primary: (
      0: #000000,
      10: #380d00,
      20: #5c1a00,
      25: #6e2100,
      30: #822800,
      35: #952f00,
      40: #aa3700,
      50: #cc4e1a,
      60: #ee6732,
      70: #ff8c61,
      80: #ffb59b,
      90: #ffdbcf,
      95: #ffede8,
      98: #fff8f6,
      99: #fffbff,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #2c160e,
      20: #442a21,
      25: #50352b,
      30: #5d4036,
      35: #6a4b41,
      40: #77574c,
      50: #926f64,
      60: #ad887c,
      70: #caa396,
      80: #e7bdb0,
      90: #ffdbcf,
      95: #ffede8,
      98: #fff8f6,
      99: #fffbff,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #221b00,
      20: #393005,
      25: #453b0f,
      30: #51461a,
      35: #5d5225,
      40: #695e2f,
      50: #837745,
      60: #9e905d,
      70: #b9ab75,
      80: #d6c68e,
      90: #f3e2a7,
      95: #fff0bf,
      98: #fff9ee,
      99: #fffbff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
  purple: (
    selector: ".theme-purple",
    primary: (
      0: #000000,
      10: #35003f,
      20: #570066,
      25: #69007a,
      30: #7b008f,
      35: #8c10a1,
      40: #9a25ae,
      50: #b744ca,
      60: #d560e6,
      70: #f07eff,
      80: #f9abff,
      90: #ffd6fe,
      95: #ffebfb,
      98: #fff7fa,
      99: #fffbff,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #251626,
      20: #3b2b3c,
      25: #473647,
      30: #534153,
      35: #5f4d5f,
      40: #6b586b,
      50: #857184,
      60: #a08a9e,
      70: #bba4b9,
      80: #d7bfd5,
      90: #f4dbf1,
      95: #ffebfb,
      98: #fff7fa,
      99: #fffbff,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #33110c,
      20: #4c251f,
      25: #593029,
      30: #673b34,
      35: #74463f,
      40: #82524a,
      50: #9e6a62,
      60: #ba837a,
      70: #d89d93,
      80: #f6b8ad,
      90: #ffdad4,
      95: #ffedea,
      98: #fff8f6,
      99: #fffbff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
  amber: (
    selector: ".theme-amber",
    primary: (
      0: #000000,
      10: #261a00,
      20: #3f2e00,
      25: #4d3800,
      30: #5b4300,
      35: #6a4e00,
      40: #785900,
      50: #977100,
      60: #b78a00,
      70: #d8a300,
      80: #fabd00,
      90: #ffdf9e,
      95: #ffefd4,
      98: #fff8f2,
      99: #fffbff,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #241a04,
      20: #3a2f15,
      25: #463a1f,
      30: #52452a,
      35: #5f5134,
      40: #6b5d3f,
      50: #857556,
      60: #a08f6e,
      70: #bba987,
      80: #d8c4a0,
      90: #f5e0bb,
      95: #ffefd4,
      98: #fff8f2,
      99: #fffbff,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #072109,
      20: #1d361c,
      25: #284126,
      30: #334d31,
      35: #3e593c,
      40: #4a6547,
      50: #627e5e,
      60: #7b9876,
      70: #95b390,
      80: #b0cfaa,
      90: #ccebc4,
      95: #dafad2,
      98: #ecffe4,
      99: #f6fff0,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
  green: (
    selector: ".theme-green",
    primary: (
      0: #000000,
      10: #002108,
      20: #003914,
      25: #00461a,
      30: #005320,
      35: #006026,
      40: #006e2c,
      50: #008a3a,
      60: #1da64b,
      70: #43c263,
      80: #62df7c,
      90: #7ffc95,
      95: #c6ffc7,
      98: #ebffe7,
      99: #f6fff1,
      100: #ffffff,
    ),
    secondary: (
      0: #000000,
      10: #0f1f11,
      20: #243425,
      25: #2f402f,
      30: #3a4b3a,
      35: #455745,
      40: #516351,
      50: #697c68,
      60: #839681,
      70: #9db09b,
      80: #b8ccb6,
      90: #d4e8d1,
      95: #e2f6df,
      98: #ebffe7,
      99: #f6fff1,
      100: #ffffff,
    ),
    tertiary: (
      0: #000000,
      10: #001f24,
      20: #00363d,
      25: #114148,
      30: #1f4d54,
      35: #2c5960,
      40: #39656c,
      50: #527e86,
      60: #6c98a0,
      70: #86b3bb,
      80: #a1ced7,
      90: #bdeaf3,
      95: #d1f8ff,
      98: #edfcff,
      99: #f6feff,
      100: #ffffff,
    ),
    neutral: (
      0: #000000,
      10: #1a202e,
      20: #313034,
      25: #3c3b3f,
      30: #47464a,
      35: #535256,
      40: #5f5e62,
      50: #78767a,
      60: #929094,
      70: #adaaaf,
      80: #c8c5ca,
      90: #e5e1e6,
      95: #f3eff4,
      98: #fcf8fd,
      99: #fffbff,
      100: #ffffff,
      4: #0e0e11,
      6: #131316,
      12: #1a202e,
      17: #10131a,
      22: #353438,
      24: #39393c,
      87: #dcd9de,
      92: #eae7ec,
      94: #f0edf1,
      96: #f6f2f7,
    ),
    neutral-variant: (
      0: #000000,
      10: #1b1b23,
      20: #303038,
      25: #3b3b43,
      30: #232936,
      35: #52515b,
      40: #5e5d67,
      50: #777680,
      60: #918f9a,
      70: #acaab4,
      80: #c8c5d0,
      90: #e4e1ec,
      95: #f2effa,
      98: #fcf8ff,
      99: #fffbff,
      100: #ffffff,
    ),
    error: (
      0: #000000,
      10: #410002,
      20: #690005,
      25: #7e0007,
      30: #93000a,
      35: #a80710,
      40: #ba1a1a,
      50: #de3730,
      60: #ff5449,
      70: #ff897d,
      80: #ffb4ab,
      90: #ffdad6,
      95: #ffedea,
      98: #fff8f7,
      99: #fffbff,
      100: #ffffff,
    ),
  ),
);


:root,:host{--sys-background:#111318;--sys-on-background:#e2e2e9;--sys-surface:#111318;--sys-surface-dim:#111318;--sys-surface-bright:#37393e;--sys-surface-container-lowest:#0c0e13;--sys-surface-container-low:#1a1b20;--sys-surface-container:#1e2025;--sys-surface-container-high:#282a2f;--sys-surface-container-highest:#33353a;--sys-on-surface:#e2e2e9;--sys-surface-variant:#44474e;--sys-on-surface-variant:#c4c6d0;--sys-inverse-surface:#e2e2e9;--sys-inverse-on-surface:#2f3036;--sys-outline:#8e9099;--sys-outline-variant:#44474e;--sys-shadow:#000000;--sys-scrim:#000000;--sys-surface-tint:#acc7ff;--sys-primary:#acc7ff;--sys-on-primary:#0f2f60;--sys-primary-container:#2a4678;--sys-on-primary-container:#d8e2ff;--sys-inverse-primary:#435e91;--sys-secondary:#acc7ff;--sys-on-secondary:#0e2f60;--sys-secondary-container:#2a4678;--sys-on-secondary-container:#d7e2ff;--sys-tertiary:#e9b5ee;--sys-on-tertiary:#47214f;--sys-tertiary-container:#603767;--sys-on-tertiary-container:#fed6ff;--sys-error:#ffb4ab;--sys-on-error:#561e19;--sys-error-container:#73342d;--sys-on-error-container:#ffdad5;--sys-neutral:#76777d;--sys-neutral-variant:#74777f;--sys-primary-fixed:#d8e2ff;--sys-primary-fixed-dim:#acc7ff;--sys-on-primary-fixed:#001a40;--sys-on-primary-fixed-variant:#2a4678;--sys-secondary-fixed:#d7e2ff;--sys-secondary-fixed-dim:#acc7ff;--sys-on-secondary-fixed:#001a40;--sys-on-secondary-fixed-variant:#2a4678;--sys-tertiary-fixed:#fed6ff;--sys-tertiary-fixed-dim:#e9b5ee;--sys-on-tertiary-fixed:#300939;--sys-on-tertiary-fixed-variant:#603767;--sys-inverse-secondary:#435e91;--sys-inverse-tertiary:#7a4f81;--sys-inverse-error:#904a43;--sys-error-fixed:#ffdad5;--sys-error-fixed-dim:#ffb4ab;--sys-on-error-fixed:#3b0907;--sys-on-error-fixed-variant:#73342d;}
